<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/invoice.png" alt />
        <span class="center">客户管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">客户列表</span>
      </div>

      <div style="display: flex; align-items: center">
        <div style="display: flex; align-items: center">
          <span class="seach_text">关键字:</span>
          <el-input
            placeholder="请输入公司名称"
            v-model="keywords"
            @input="loadData('seath')"
            size="mini"
            style="margin-right: 10px; width: 250px"
            prefix-icon="el-icon-search"
            clearable
          ></el-input>
        </div>
        <el-button style="margin-left:20px" type="primary" size="small" @click="addProject()">新建</el-button>
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column label="企业名称">
        <template slot-scope="scope">
          <span class="clickColor" @click="goDetail(scope.row.code)">{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="企业统一信用编码">
        <template slot-scope="scope">
          <span>{{ scope.row.creditCode }}</span>
        </template>
      </el-table-column>

      <!-- <el-table-column label="联系人">
        <template slot-scope="scope">
          <span>{{ scope.row.username }}</span>
        </template>
      </el-table-column>
      <el-table-column label="联系方式">
        <template slot-scope="scope">
          <span>{{ scope.row.userPhone }}</span>
        </template>
      </el-table-column>-->

      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="goedit(scope.row.code)" type="text">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin:0 10px">每页{{ pageSize }}条</span>
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog
      class="showImgDialog"
      width="680px"
      :center="true"
      :title="dialogTitle"
      :visible.sync="showImgVisible"
      :append-to-body="true"
    >
      <div class="imgBox">
        <img :src="dialogImgUrl" class="img-resource" alt />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { api } from '../../api/base/index';
export const customerList = api()('customer.list.json');

export default {
  data () {
    return {
      tableData: [
        {
          name: '银企直联',
          creditCode: 'x135468794556',
          code: 'hy306846xz'
        }
      ],
      dialogTitle: '',
      keywords: '',
      showImgVisible: false,
      dialogImgUrl: '',
      pageSize: 10,
      pageNumber: 1,
      total: 0,
    }
  },
  created () {
    // this.loadData()
  },
  methods: {
    showImg (img, title) {
      this.dialogImgUrl = img;
      this.dialogTitle = title;
      console.log(this.dialogImgUrl);
      this.showImgVisible = true;
    },
    addProject () {
      this.$router.push({
        path: '/system/customer/add',
      });
    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.loadData();
    },
    loadData (seath) {
      if (seath) {
        this.pageNumber = 1;
      }
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        keywords: this.keywords
      };
      customerList(params).then((res) => {
        for (let i = 0; i < res.list.length; i++) {
          res.list[i].passShow = false
        }
        this.tableData = res.list;
        console.log(this.tableData)
        this.total = res.total;
      });
    },
    goShow (code) {

      this.tableData.forEach(it => {
        if (it.code === code) {
          console.log(it)
          it.passShow = !it.passShow
        }
      })

    },
    goedit (code) {
      this.$router.push({
        path: '/system/customer/add',
        query: { code },
      });
    },
    goDetail (code) {
      this.$router.push({
        path: '/system/customer/detail',
        query: { code },
      });
    }
  },
}
</script>
<style lang="scss" scope>
.showImgDialog .imgBox {
  display: flex;
}
.img-resource {
  width: 300px;
  height: 250px;
  margin: 0 auto;
}
.font-img {
  font-size: 26px;
  cursor: pointer;
  color: cadetblue;
}
</style>